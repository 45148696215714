

.filters-horizontal-scroll {
  width: 100% !important;
  /*bottom: 0 !important;*/
  /*left: 2px !important;*/
  /*top: 0 !important;*/
  position: relative !important;
  margin-top: 8px !important;
}

/*
if .swiper-wrapper has sibiling with .swiper-scrollbar-lock class then add margin-bottom to .swiper-wrapper
*/
.swiper-wrapper ~ .swiper-scrollbar-lock {
  margin-bottom: 10px;
}
